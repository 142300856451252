import { Link } from "gatsby"
import React from "react"

export default () => (
  <header id="header" className="bg-light">
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/">Stehschreibtische</Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="badge badge-dark">9</span> Stehschreibtische
                </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/stehschreibtisch-bekant.php/">IKEA BEKANT</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-skarsta.php/">IKEA SKARSTA</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-desktopia-pro.php/">Desktopia Pro</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-masterlift2.php/">Inwerk MASTERLIFT 2</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-songmics.php/">SONGMICS LAD08NW</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-xbhm.php/">BÜMÖ XBHM</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-eadjust.php/">celexon Professional eAdjust-65120</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-o-vos19-6.php/">Hammerbacher O/VOS19/6</Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="badge badge-dark">6</span> Für Schüler und Kinder
                </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/kettler-college-box-2.php/">Kettler College Box 2</Link>
              <Link className="dropdown-item" to="/kettler-kids-comfort-2.php/">Kettler Kids Comfort 2</Link>
              <Link className="dropdown-item" to="/kettler-cool-top-2.php/">Kettler Cool Top 2</Link>
              <Link className="dropdown-item" to="/kettler-little.php/">Kettler Little</Link>
              <Link className="dropdown-item" to="/links-cetrix.php/">Links Cetrix</Link>
              <Link className="dropdown-item" to="/idimex-mario.php/">Idimex Mario</Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="badge badge-dark">4</span> Tischgestelle
                </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/inwerk-imperio-lift-hoehenverstellbares-tischgestell.php/">Inwerk Imperio Lift</Link>
              <Link className="dropdown-item" to="/stehschreibtisch-top-eco-classic.php/">TOP-ECO Classic V2</Link>
              <Link className="dropdown-item" to="/exeta-hoehenverstellbares-tischgestell.php/">Exeta Höhenverstellbar</Link>
              <Link className="dropdown-item" to="/flexispot-h2w-hoehenverstellbares-tischgestell.php/">Flexispot H2W</Link>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/vergleich.php/">Vergleich</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/ratgeber.php/">Ratgeber</Link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
)
